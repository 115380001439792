<template>
  <div class="float-status-info position-absolute bg-white p-2 rounded">
    <small class="bg-light text-dark-darken mb-3 d-block text-center font-weight-bold p-1 rounded"
      >Status por cor</small
    >
    <p
      v-for="(status, index) in statuses"
      :key="`status-${index}`"
      class="text-dark-darken mb-1 d-flex aling-items-center"
    >
      <span class="color-label d-block rounded mr-1" :style="{ background: status.color }"></span>
      <b class="ml-1">{{ status.text }}</b>
    </p>
  </div>
</template>

<script>
export default {
  name: 'float-status-info',
  props: {
    statuses: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.float-status-info {
  max-width: 300px;
  top: 0;
  left: 0;
  z-index: 10;

  p {
    font-size: 0.8rem;
  }

  .color-label {
    width: 18px;
    height: 18px;
  }
}
</style>
